import React from 'react';
// import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { logo } from "../assets";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-4 mt-auto">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center border-t border-gray-700 pt-4">

          <div className="flex items-center mb-4 md:mb-0">
            <img src={logo} alt="Logo" className="h-12 w-auto rounded-full" />
          </div>

          <div className="text-center md:text-right text-gray-400 text-sm space-y-1">
            <p className="flex items-center justify-center md:justify-end">
              <span role="img" aria-label="address" className="mr-2">🏠</span> 8 Rue des Mérovingiens
              L-8070, Bertrange
            </p>
            <p className="flex items-center justify-center md:justify-end">
              <span role="img" aria-label="phone" className="mr-2">📞</span>
              <a href="tel:+352621429442" className="text-gray-400 hover:text-white">
                +352 621 429 442
              </a>
            </p>
            <p className="flex items-center justify-center md:justify-end">
              <span role="img" aria-label="email" className="mr-2">✉️</span>
              <a href="mailto:freitascleaning97@gmail.com" className="text-gray-400 hover:text-white">
                freitascleaning97@gmail.com
              </a>
            </p>

          </div>
        </div>

        <div className="mt-4 text-center md:text-right">
          <p className="text-xs text-gray-500">&copy; 2024 Industrial Freitas Clean. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
